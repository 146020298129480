import React, { useState, useEffect } from 'react';
import './src/styles/bootstrap-grid/bootstrap-grid.min.css';

import Layout from './src/components/Layout';

import { DestinationProvider } from './src/contexts/DestinationContext';
import { getClosestCity } from './src/helpers';

const Wrapper = ({ element, props }) => {
  const [cityName, setCityName] = useState('');
  const value = { cityName, setCityName };

  const previousLocation =
    typeof window !== 'undefined' && window.sessionStorage.getItem('location');

  useEffect(() => {
    if (!previousLocation) {
      navigator.geolocation.getCurrentPosition(
        (currentLocation) => {
          const currentCoords = currentLocation.coords;

          const closestCity = getClosestCity(
            currentCoords.latitude,
            currentCoords.longitude
          );

          if (
            typeof window !== 'undefined' &&
            !window.sessionStorage.getItem('location-lat')
          ) {
            window.sessionStorage.setItem('destination', closestCity);
            setCityName(closestCity);
          }

          if (typeof window !== 'undefined') {
            window.sessionStorage.setItem(
              'location-lat',
              currentCoords.latitude.toString()
            );
          }

          if (typeof window !== 'undefined') {
            window.sessionStorage.setItem(
              'location-lng',
              currentCoords.longitude.toString()
            );
          }
        },
        (locationError) =>
          typeof window !== 'undefined' &&
          window.sessionStorage.setItem('error', locationError.message),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  }, []);

  return (
    <DestinationProvider value={value}>
      <Layout {...props}>{element}</Layout>
    </DestinationProvider>
  );
};

export default Wrapper;
