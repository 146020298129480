import React from 'react';
import Select, {components} from 'react-select';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';


const styles = {
    container: () => ({
        width: 155,
        height: '100%',
        fontFamily: 'D-DINExp',
        fontSize: 13,
        backgroundColor: 'black',
    }),

    control: (provided) => ({
        ...provided,
        padding: '0 20px 0 10px',
        backgroundColor: 'black',
        borderRadius: 0,
        border: 'none',
        borderLeft: '1px solid #6D6E71',
        boxShadow: 'none',
    }),

    valueContainer: provided => ({
        ...provided,
        background: 'black',
        color: 'white',
        width: '100%',
    }),

    singleValue: provided => ({
        ...provided,
        fontFamily: 'D-DINExp',
        fontSize: 13,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: 'white',
    }),

    menuPortal: provided => ({
        ...provided,
        zIndex: 100,
    }),

    menu: provided => ({
        ...provided,
        zIndex: 100,
        fontSize: 13,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: 'white',
        borderRadius: 0,
        backgroundColor: 'black',
    }),

    option: provided => ({
        ...provided,
        backgroundColor: 'black',
        ':hover': {
            backgroundColor: 'gray',
        }
    }),
}

const Control = ({children, ...props}) => (
    <components.Control {...props}>
        <StaticImage src="../../../static/svg/MapPin.svg" alt="" />
        {children}
    </components.Control>
);

const DoubleArrow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 8px;
`;

const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <DoubleArrow>
            <StaticImage src="../../../static/svg/arrow_up.svg" alt="" />
            <StaticImage src="../../../static/svg/arrow_down.svg" alt="" />
        </DoubleArrow>
    </components.DropdownIndicator>
);

const options = [
    { value: 'Guelph', label: 'Guelph' },
    { value: 'Cambridge', label: 'Cambridge' }
]

const StyledSelect = ({ onChange, currentDestination }) => {
    const getPortal = () => {
        if (typeof window !== 'undefined') {
            return window.document.body;
        }
        return null;
    }

    return (
        <Select
            onChange={onChange}
            defaultValue={{value: currentDestination, label: currentDestination}}
            options={options}
            styles={styles}
            menuPortalTarget={getPortal()}
            components={{ Control, IndicatorSeparator: null, DropdownIndicator }}
        />
    )
}
  

export default StyledSelect;
