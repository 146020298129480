import React, { useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby-link';
import { StaticImage } from 'gatsby-plugin-image';

import DestinationContext from '../../contexts/DestinationContext';
import StyledSelect from '../organisms/StyledSelect';

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.8);

    .top-nav-svg {
        margin-right: 14px;
        cursor: pointer;
    }

    a{
        display: flex;
        justify-content: center;
        align-items: center;
    }

  @media (max-width: 900px) {
    display: none;
  }
`;

const TopLocationNav = () => {
  const { cityName, setCityName } = useContext(DestinationContext);

  const setLocation = (newCityName) => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('destination', newCityName);
    }
    setCityName(newCityName);
  };

  const currentDestination = () => {
    if (
      typeof window !== 'undefined' &&
      window.sessionStorage.getItem('destination')
    ) {
      return window.sessionStorage.getItem('destination');
    }
    if (cityName) {
      return cityName;
    }
    return 'Guelph';
  };

  const onChangeLocation = (option) => {
    setLocation(option.value);
    navigate(`/${option.value.toLowerCase()}/livemenu`);
  }

  return (
    <Container>
        <a href="https://www.instagram.com/ronincanna/">
            <StaticImage
                src="../../../static/svg/icon_instagram.svg"
                alt="Instagram"
                className="top-nav-svg"
            />
        </a>
      <StyledSelect onChange={onChangeLocation} currentDestination={currentDestination()}/>
    </Container>
  );
};

export default TopLocationNav;
