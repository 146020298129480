import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --pink: #D95396;
    --bgwhite: #E5E5E5;
    --white: #FFFFFF;

    --mobile: 450px;
;
  }
  html {
    font-size: 10px;
    background-color: var(--beige);
  }

  body {
    font-size: 2rem;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--pink);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    cursor: pointer;
    box-shadow: 0px 0px 4px #E94799, 0px 0px 25px #E94799;
    border-radius: 3px;
    padding: 10px 60px;
    &:hover {
      color: var(--pink);
      background: white;
      box-shadow: 0px 0px 8px #E94799, 0px 0px 40px #E94799;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

`;

export default GlobalStyles;
