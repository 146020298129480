// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessories-js": () => import("./../../../src/pages/accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-cambridge-js": () => import("./../../../src/pages/cambridge.js" /* webpackChunkName: "component---src-pages-cambridge-js" */),
  "component---src-pages-cambridge-livemenu-js": () => import("./../../../src/pages/cambridge/livemenu.js" /* webpackChunkName: "component---src-pages-cambridge-livemenu-js" */),
  "component---src-pages-cannabis-beverages-js": () => import("./../../../src/pages/cannabis-beverages.js" /* webpackChunkName: "component---src-pages-cannabis-beverages-js" */),
  "component---src-pages-cannabis-edibles-js": () => import("./../../../src/pages/cannabis-edibles.js" /* webpackChunkName: "component---src-pages-cannabis-edibles-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-guelph-js": () => import("./../../../src/pages/guelph.js" /* webpackChunkName: "component---src-pages-guelph-js" */),
  "component---src-pages-guelph-livemenu-js": () => import("./../../../src/pages/guelph/livemenu.js" /* webpackChunkName: "component---src-pages-guelph-livemenu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livemenu-cambridge-js": () => import("./../../../src/pages/livemenu-cambridge.js" /* webpackChunkName: "component---src-pages-livemenu-cambridge-js" */),
  "component---src-pages-livemenu-guelph-js": () => import("./../../../src/pages/livemenu-guelph.js" /* webpackChunkName: "component---src-pages-livemenu-guelph-js" */),
  "component---src-pages-livemenu-js": () => import("./../../../src/pages/livemenu.js" /* webpackChunkName: "component---src-pages-livemenu-js" */),
  "component---src-pages-livemenu-london-js": () => import("./../../../src/pages/livemenu-london.js" /* webpackChunkName: "component---src-pages-livemenu-london-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-london-livemenu-js": () => import("./../../../src/pages/london/livemenu.js" /* webpackChunkName: "component---src-pages-london-livemenu-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

