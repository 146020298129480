export const cities = {
  Guelph: {
    lat: 43.54124483001584,
    lng: -80.24363115353586,
  },
  Cambridge: {
    lat: 43.423435163514014,
    lng: -80.28735424599385,
  },
};

export const getClosestCity = (lat, lng) => {
  const { Guelph, Cambridge } = cities;
  const distances = [
    (Guelph.lat - lat) ** 2 + (Guelph.lng - lng) ** 2,
    (Cambridge.lat - lat) ** 2 + (Cambridge.lng - lng) ** 2,
  ];
  const minDistance = Math.min(...distances);
  const cityIndex = distances.findIndex((distance) => distance === minDistance);

  return Object.keys(cities)[cityIndex];
};
