import React from 'react';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Navbar from './Nav/Navbar';
import AgeCheck from './AgeCheck';

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Typography />
    <Navbar />
    <AgeCheck />
    {children}
  </>
);

export default Layout;
