import { createGlobalStyle } from 'styled-components';
import fontGlossBloom from '../assets/fonts/Gloss_And_Bloom.ttf';
import fontDDINEXP from '../assets/fonts/D-DINExp.woff2';
import fontDDINEXPBOLD from '../assets/fonts/D-DINExp-Bold.woff2';
// import fontCorbel from '../assets/fonts/CORBEL.TTF';
import fontCorbelBold from '../assets/fonts/CorbelBold.ttf';
import fontSpaceAge from '../assets/fonts/spaceAge.woff';

const Typography = createGlobalStyle`
  @font-face {
    font-family: Space-Age;
    src: url(${fontSpaceAge});
  }

  @font-face {
    font-family: Gloss-And-Bloom;
    src: url(${fontGlossBloom});
  }

  @font-face {
    font-family: D-DINExp;
    src: url(${fontCorbelBold});
  }

  @font-face {
    font-family: D-DINExpBold;
    src: url(${fontCorbelBold});
  }

  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }

  p, li {
    letter-spacing: 0.5px;
    font-family: D-DINExp, serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0;
  }

  h1, h2 {
    font-family: Gloss-And-Bloom, serif;
    color: white;
  }

  h1 {
    font-size: 8.5rem;

    @media screen and (max-width: 1300px) {
      font-size: 6rem;
    }
    @media screen and (max-width: 450px) {
      font-size: 3.8rem;
    }
  }

  h2 {
    font-size: 6rem;
    @media screen and (max-width: 450px) {
      font-size: 3rem;
    }

  }

  h3, h4, h5, h6 {
    font-family: D-DINExpBold, serif;
    font-weight: bold;
  }

  h4 {
    font-family: Space-Age, serif;
    color: var(--pink);
  }

  p {
    font-family: D-DINExp, serif;
    @media screen and (max-width: 450px) {
      font-size: 1.5rem;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
    }

    &:visited {
      color: inherit;
    }

    &:active {
      color: inherit;
    }
  }

  mark, .mark {
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }

  button {
    font-family: Space-Age, serif;
  }
`;
export default Typography;
