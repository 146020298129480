import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Burger from './Burger';
import TopLocationNav from './TopLocationNav';

const Nav = styled.nav`
  padding: 20px 15px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10;
  text-decoration: none;
  width: 100%;

  .logo-img {
    max-height: 90px;
    @media screen and (max-width: 900px) {
      max-height: 50px;
      display: flex;
      justify-content: center;
    }
  }

  .outlineA {
    outline: 0 solid green;
  }

  .center-col {
    display: flex;
    justify-content: center;
  }

  .end-col {
    display: flex;
    justify-content: flex-end;
  }

  background-color: rgba(0, 0, 0, 0.6);

  @supports (
    (-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px))
  ) {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
  }

  @media (max-width: 900px) {
    top: 0;
  }
`;

const CTANav = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  button {
    font-size: clamp(12px, 1vw, 20px);
    letter-spacing: 3px;
    padding: 1vw 1vw;
    margin-left: 0.5vw;
    font-family: D-DINExp, serif;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    border: 3px white solid;
    box-shadow: 0 0 0 white, 0 0 0 white;

    &:hover {
      border: 3px var(--pink) solid;
      color: var(--pink);
      font-weight: 900;
      box-shadow: 0 0 5px var(--pink), 0 0 5px var(--pink);
    }
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const Navbar = () => (
  <>
    <TopLocationNav />
    <Nav>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-lg-2 col-12 outlineA">
            <Link to="/">
              <img src="/roninLogo.svg" alt="Ronin logo" className="logo-img" />
            </Link>
          </div>
          <div className="col-md-7 col-lg-8 outlineA">
            <Burger />
          </div>
          <div className="col-md-2 outlineA end-col">
            <CTANav>
              <Link to="/locations">
                <button className="cta-button" type="button">
                  <Link to="/locations">SHOP NOW</Link>
                </button>
              </Link>
            </CTANav>
          </div>
        </div>
      </div>
    </Nav>
  </>
);

export default Navbar;
