import React, { isValidElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';





export default function AgeCheck(){
    const cookies = new Cookies();
    const isAnAdult = cookies.get('isAnAdult');

    const [failState, setFailState] = useState(false);  
    const [ageGood, setAgeGood] = useState(true); 
    

    useEffect(() => {
        setAgeGood(isAnAdult);
    }, []);
    

    function ageCheck(e) {
    
        //----------Set-up Date
        var today = new Date;
        var days = 14; //number of days until they must go through the age checker again.
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        
        //--------Hide Elements
        setAgeGood(true);
    
        //--------Set Cookie
        cookies.set('isAnAdult', true, { path: '/', expires: date });
    
    };
    
    function ageFail(e) {
        setFailState(true);
        setAgeGood(false);
    }
    
    const AgeCheckStyles = styled.div`
        h2{
            color: black;
        }

        .mymodal_cover{
            display: ${({ ageGood }) => (ageGood) ?  'none' : 'block'};
            z-index: 5000;
            background: black;
            opacity: 0.5;
            width: 100%;
            height: 100%;
            position: fixed;
        }
    
        .mymodal{
            z-index: 6000;
            opacity: 1;
            position: fixed;
            top: calc(50% - 200px);
            left: calc(50% - 440px);
            width: 800px;
            height: 440px;
            background-color: white;
            border-radius: 5px;
    
            flex-direction: row;
            justify-content: center;
            display: ${({ ageGood }) => (ageGood) ?  'none' : 'flex'};
        }
    
        @media screen and (max-width: 875px){
            .mymodal{
                width: 500px;
                height: 300px;
                top: calc(50% - 150px);
                left: calc(50% - 250px);
            }
        }
        @media screen and (max-width: 450px){
            .mymodal{
                width: 295px;
                height: 280px;
                top: calc(50% - 112.5px);
                left: calc(50% - 147.5px);
            }
        }
    
        .modal_content{
            text-align: center;
            margin: 50px;
        }
        @media screen and (max-width: 450px){
            .modal_content{
                margin: 25px;
                max-width: 300px;
            }
        }
    
        .modal_text h2{
            font-size: 20px !important;
            margin-bottom: 0px;
        }
        @media screen and (max-width: 875px){
            .modal_text h2{
                font-size: 25px;
            }
        }
        @media screen and (max-width: 450px){
            .modal_text h2{
                font-size: 17px;
            }
        }
    
            
        .sub_text{
            font-size: 25px;
            text-align: center;
            margin-top: 50px;
            margin-bottom: 50px;
            padding: 0px 185px;
        }
        #FailText{
            display: none;
        }
        @media screen and (max-width: 875px){
            .sub_text{
                font-size: 15px;
                margin-top: 30px;
                margin-bottom: 30px;
                padding: 0px 100px;
            }
        }
        @media screen and (max-width: 450px){
            .sub_text{
                font-size: 15px;
                margin-top: 25px;
                margin-bottom: 25px;
                padding: 0px 50px;
            }
        }
    
        .modal_buttons{
            margin-top: 0px;
            display: flex;
            justify-content: center;
            width: 100%;
        }
    
        .modal_buttons button{
            margin: 0px 20px;
            padding: 10px 60px;
            border-radius: 40px;
            
        }
    
        @media screen and (max-width: 450px){
            .modal_buttons{
                padding: 8px 10px;
            }
            .modal_buttons button{
                padding: 10px 40px;
            }
        }
        button{
            cursor: pointer;
        }
        #yes_btn{
            display: ${({ failState }) => (failState) ?  'none' : 'block'};
        }
        #no_btn{
            display: ${({ failState }) => (failState) ?  'none' : 'block'};
        }
        #DefaultText{
            display: ${({ failState }) => (failState) ?  'none' : 'block'};
        }
        #FailText{
            display: ${({ failState }) => (failState) ?  'block' : 'none'};
        }
    `;
    
    if (ageGood=='true'){
        return(
            <div></div>
        );
    }
    return(
        <AgeCheckStyles ageGood={ageGood} failState={failState}>
                    <div id="AgeVerifCover" className="mymodal_cover">
                    </div>
                    <div id="AgeVerif" className="mymodal">
                            <div className="modal_content">
                                <div className="modal_text">
                                    <h2> When is your birthday?</h2>
                                    <div className="sub_text">
                                        <div id="DefaultText">
                                            Please verify that you are 19 years of age or older to enter the site
                                        </div>
                                        <div id="FailText">
                                            Sorry, come back when you're 19.
                                        </div>
                                    </div>
                                </div>
                                <div className="modal_buttons">
                                    <button id="yes_btn" onClick={ageCheck}>YES</button>
                                    <button id="no_btn" onClick={ageFail}>NO</button>
                                </div>
                            </div>
                    </div>
                </AgeCheckStyles>
    );
}