import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import DropdownItem from './DropdownItem';

const Ul = styled.ul`
  flex-flow: row nowrap;
  list-style: none;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0;
  font-size: clamp(1.5rem, 1vw, 2.1rem);

  li {
    padding: 0 1vw;
    color: white;
  }

  color: var(--lightblack);
  @media (max-width: 900px) {
    background-color: #0e1111;
    padding: 0 0;
    justify-content: flex-start;
    li {
      padding: 22px 0;
      font-size: 35px;
    }

    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    padding-top: 10.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 5000;
    margin-top: 0;
    li {
      color: white;
    }
  }
`;

const RightNavButtonsCTA = styled.li`
  display: flex;
  justify-content: center;
  max-width: 100%;

  button {
    font-size: clamp(12px, 1vw, 0px);
    letter-spacing: 3px;
    padding: 10px 10px;
    margin-left: 0.5vw;
    font-family: D-DINExp, serif;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    border: 3px white solid;
    box-shadow: 0 0 0 white, 0 0 0 white;
    @media (max-width: 900px) {
      font-size: 30px !important;
    }
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const DDMenuStyle = styled.div`
  background-color: #0e1111;
  position: absolute;
  cursor: pointer;
  border-radius: 7px;
  z-index: 3;
  border: 1px solid rgba(255, 255, 255, 0.075);

  ul {
    list-style: none;
    margin: 0 0;
    padding: 0 0;
    padding-top: 10px;
  }

  li {
    color: white;
    padding: 10px 12px;

    &:hover {
      color: var(--pink);
      transform: translateX(3px);
    }
  }
`;

const LocDDStyle = styled.div`
  @media (max-width: 900px) {
    transform: ${({ expandedCAT }) =>
      !expandedCAT ? 'translateY(0px)' : 'translateY(200px)'};
  }
`;

const ContactStyle = styled.div`
  @media (max-width: 900px) {
    transform: ${({ expandedLOC, expandedCAT }) =>
      expandedLOC || expandedCAT ? 'translateY(200px)' : 'translateY(0px)'};
    display: ${({ expandedLOC, expandedCAT }) =>
      expandedLOC && expandedCAT ? 'none' : 'block'};
  }

  &:hover {
    color: var(--pink);
  }
`;

const RightNav = ({ open, toggleOpen }) => {
  const [isExpandedCAT, setExpandedCAT] = useState(false);
  const [isExpandedLOC, setExpandedLOC] = useState(false);
  const [isOpenRightNav, setOpenRightNav] = useState(true);

  return (
    <>
      <Ul open={open}>
        <RightNavButtonsCTA>
          <Link to="/locations" onClick={() => toggleOpen(!open)}>
            <button type="button">SHOP NOW</button>
          </Link>
        </RightNavButtonsCTA>
        <li>
          <div onClick={() => setExpandedCAT(!isExpandedCAT)}>
            <DropdownItem title="CATEGORIES" className="catdd">
              <DDMenuStyle>
                <ul>
                  <li>
                    <Link to="/accessories" onClick={() => toggleOpen(!open)}>
                      ACCESSORIES
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cannabis-beverages"
                      onClick={() => toggleOpen(!open)}
                    >
                      BEVERAGES
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cannabis-edibles"
                      onClick={() => toggleOpen(!open)}
                    >
                      EDIBLES
                    </Link>
                  </li>
                  <li>
                    <Link to="/brands" onClick={() => toggleOpen(!open)}>
                      BRANDS
                    </Link>
                  </li>
                </ul>
              </DDMenuStyle>
            </DropdownItem>
          </div>
        </li>
        <li>
          <LocDDStyle
            expandedLOC={isExpandedLOC}
            onClick={() => setExpandedLOC(!isExpandedLOC)}
          >
            <DropdownItem title="LOCATIONS">
              <DDMenuStyle>
                <ul>
                  <li>
                    <Link to="/guelph" onClick={() => toggleOpen(!open)}>
                      GUELPH
                    </Link>
                  </li>
                  <li>
                    <Link to="/cambridge" onClick={() => toggleOpen(!open)}>
                      CAMBRIDGE
                    </Link>
                  </li>
                </ul>
              </DDMenuStyle>
            </DropdownItem>
          </LocDDStyle>
        </li>
        <li>
          <Link to="/blogs/" onClick={() => toggleOpen(!open)}>
            BLOG
          </Link>
        </li>
        <li>
          <Link to="/about-us/" onClick={() => toggleOpen(!open)}>
            ABOUT US
          </Link>
        </li>
        <li>
          <Link to="/faq/" onClick={() => toggleOpen(!open)}>
            FAQ
          </Link>
        </li>
      </Ul>
    </>
  );
};

export default RightNav;
