import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const StyledBurger = styled.div`
  width: 4rem;
  height: 4rem;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 80000;
  display: none;
  background: ${({ open }) => (open ? 'var(--green)' : 'var(--beige)')};
  padding: 1rem 1rem;
  border-radius: 50%;
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }
  div {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? 'white' : 'var(--pink)')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const Burger = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} toggleOpen={setOpen}/>
    </>
  );
};
export default Burger;
