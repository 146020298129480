import React, { useState } from 'react';
import styled from 'styled-components';

import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';

export default function DropdownItem({ title, children }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const DDItemStyle = styled.div`
    cursor: pointer;
    color: ${({ expanded }) => (expanded ? 'var(--pink)' : 'white')};

    &:hover {
      cursor: pointer;
      color: var(--pink);
    }
  `;

  return (
    <DDItemStyle
      onClick={() => setIsExpanded(!isExpanded)}
      expanded={isExpanded}
    >
      {title} {isExpanded ? <RiArrowUpSFill /> : <RiArrowDownSFill />}
      {isExpanded && children}
    </DDItemStyle>
  );
}
